import TicketsDashboard from '../../views/tickets/index.vue';

export default [
  {
    path: '/tickets',
    name: 'ticketsDashboard',
    component: TicketsDashboard,
    meta: { requiresAuth: true, role: 'admin' }
  },
  // {
    // path: '/admin/settings',
    // name: 'AdminSettings',
    // component: AdminSettings
  // }
];
