<template>
    <div class="position-relative">
      <input
        class="form-control"
        :list="listId"
        :id="inputId"
        v-model="inputValue"
        :placeholder="placeholder"
        ref="inputField"
        :disabled="disabled"
        @input="updateValue"
      />
      <button v-if="inputValue" @click="clearInput" class="btn-clear">
        &times;
      </button>
      <datalist :id="listId">
        <option v-for="item in items" :key="item.id" :value="item.name">
          {{ item.name }}
        </option>
      </datalist>
    </div>
  </template>
  
  <script>
  export default {
    name: "ShopStoresInput",
    props: {
      inputId: {
        type: String,
        required: true
      },
      listId: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      placeholder: {
        type: String,
        default: "Type to search..."
      },
      disabled: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        default: () => ({ id: '', name: '' }) // Valor predeterminado envuelto en una función
      }
    },
    data() {
      return {
        inputValue:this.value ? this.value.name : '',
      };
    },
    watch: {
      value(val) {
        this.inputValue = val ? val.name : '';
      }
    },
    methods: {
      updateValue() {
        console.log("updateValue")
        console.log(this.inputValue)
        const selectedItem = this.items.find(item => item.name === this.inputValue);
        this.$emit('input', selectedItem || { name: this.inputValue });
      },
      clearInput() {
        this.inputValue = "";
        this.$emit('inputValue', { id: '', name: '' });
        this.$nextTick(() => {
            this.$refs.inputField.focus();
        });
      }
    }
  };
  </script>
  
  <style scoped>
    .position-relative {
      position: relative;
    }
    .btn-clear {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border: none;
      background: transparent;
      color: #000;
      font-size: 1.2rem;
      cursor: pointer;
      outline: none;
    }
    .btn-clear:focus {
      box-shadow: none;
    }
  </style>
  