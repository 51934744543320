import axios from 'axios';

const state = {
  adminData: {},
  token: localStorage.getItem('token') || '',
  user: null,
  authStatus: ''
};

const getters = {
  authError: state => state.authError
};

const actions = {

};

const mutations = {
  setAdminData(state, data) {
    state.adminData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
