import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import axios from 'axios'

import session from './session/index';
import navigation from './navigation/index';

// SHOPS
import shopTp from './shopTp/index';
import shopsProducts from './shops/index';
import tickets from './tickets/index';

// UTILS
import utils from './utils'; 

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    student: [],
    roles:[],
  },
  getters:{
    loggedIn (state) {
      return state.token !== null
    }
  },
  mutations: {
    retrieveToken (state, token) {
      state.token = token
    },
    destroyToken (state) {
      state.token = null
    },
    refreshToken (state, refresh) {
      state.refresh = refresh
    },
    setStudent (state, student) {
      state.student = student
    },
    setRoles (state, roles) {
      state.roles = roles
    },
  },
  actions: {
    async setRoles ({ commit }) {
      console.log("setRoles");
      const current = 'roles';
      const currentStorage = window.localStorage.getItem(current);
      if (currentStorage) {
        commit('setRoles', JSON.parse(currentStorage));
      } else {
        try {
          const url = process.env.VUE_APP_DOMANAPI1 + 'admin/profile/user-roles';
          const token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(token);
          await fetch(url, { 
            headers: { 
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
            })
            .then(data => data.json())
            .then(data => {
              console.log(data);
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                commit('setRoles', data);
                window.localStorage.setItem(current, JSON.stringify(data));
              }
            })
        } catch (error) {
          console.error(error)
        }
      }
    },
    retrieveToken (context, credentials) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_DOMANAPI1 + 'admin/auth/login', 
        {
          email: credentials.email,
          password: credentials.password,
        }
        )
          .then(response => {
            if( response.data.code != '500'){
              console.log(process.env.VUE_APP_DOMANAPI1);
              console.log("retrieveToken if !=500");
              const token = response.data
              console.log("token");
              console.log(token);
              window.localStorage.setItem('access_token', JSON.stringify(token))
              context.commit('retrieveToken', token)
              context.state.loggedIn = true
              resolve(response)
            } 
            // else {
            //   console.log(process.env.VUE_APP_DOMANAPI1);
            //   console.log("else");
            //   resolve(response)
            // }
          })
          // .catch(error => {
          //   reject(error)
          // })
      })
    },
    destroyToken (context) {
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.post(process.env.VUE_APP_DOMANAPI1 + 'admin/auth/logout', '', {
            headers: { Authorization: 'Bearer ' + context.state.token }
          })
            .then(response => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('student')
              localStorage.removeItem('navigation')
              localStorage.removeItem('navigationAdmin')
              context.commit('destroyToken')
              resolve(response)
              context.state.loggedIn = false
            })
            .catch(error => {
              localStorage.removeItem('access_token')
              context.commit('destroyToken')
              reject(error)
            })
        })
      }
    },
    async resetToken (context) {
      const access_token = `${window.localStorage.getItem('access_token')}`
      let store = JSON.parse(access_token);
      if(store){
        try {
          const url = "https://api.dabuho.com/api/v1/admin/auth/refreshtoken";
          axios
            .post(url, { 
                Accept: 'application/json',
                refreshtoken: `${store["refresh_token"]}` 
            })
            .then(function(response) {
                const token = response.data;
                localStorage.setItem('access_token', JSON.stringify(token));
                context.state.loggedIn = true;
                console.log("route go");
                router.go(0);
              });
        } catch (error) {
          console.error(error)
        }
      }
    },
    async setStudent ({ commit }) {
      const currentStudent = "student";
      const student = window.localStorage.getItem(currentStudent)
      if (student) {
        commit('setStudent', JSON.parse(student))
      } else {
        try {
          const url = process.env.VUE_APP_DOMANAPI1 + 'admin/data/user';
          const token = `${window.localStorage.getItem('access_token')}`;
          let store = JSON.parse(token);
          await fetch(url, {
            headers: {
              Accept: 'application/json',
              Authorization: `${store["token_type"]} ${store["access_token"]}` 
            }
          })
            .then(data => data.json())
            .then(data => {
              if (data.code == 401) {
                console.log("401");
                console.log("resetToken");
                this.dispatch('resetToken');
              } else {
                console.log('setStudent else')
                commit('setStudent', data)
                window.localStorage.setItem(currentStudent, JSON.stringify(data))
              }
            })
          //this.state.loadingThemas = false
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  modules: {
    session,
    navigation,
    shopsProducts,
    shopTp,
    tickets,
    utils,
  }
})
