<template>
    <div class="position-relative">
      <input
        :type="type"
        class="form-control"
        :id="inputId"
        v-model="inputValue"
        :placeholder="placeholder"
        :name="name"
        :disabled="disabled"
        ref="inputField"
      />
      <button v-if="inputValue" @click="clearInput" class="btn-clear">
        &times;
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "ClearableInputText",
    props: {
      inputId: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: "text"
      },
      placeholder: {
        type: String,
        default: ""
      },
      value: {
        type: String,
        default: ""
      },
      name: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      }
    },
    methods: {
      clearInput() {
        this.inputValue = "";
        this.$nextTick(() => {
            this.$refs.inputField.focus();
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .position-relative {
    position: relative;
  }
  .btn-clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: #000;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
  }
  .btn-clear:focus {
    box-shadow: none;
  }
  </style>
  