<template>
  <div class="col- align-self-center dtema_icon" v-on:click="hamb">
    <div class="hamburger">
      <i class="fas fa-bars"></i>
    </div>
  </div>
</template>

<script>

export default {
  mounted() {
  },
  methods: {
    hamb(){
        var menu = document.querySelector('.menu');
        var sup = document.querySelector('.super_container_inner');
        menu.classList.add("active");
        sup.classList.add("active");
        this.$store.dispatch("navigation/setNavigation");
        //this.$store.dispatch('setScore');
      },
  },
  
};
</script>
<style>
.hamburger {
  cursor: pointer;
}
.hamburger i {
  font-size: 20px;
  color: #ffffff;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.hamburger:hover i {
  color: #006df0;
}
.dtema_icon{
  cursor: pointer;
}
</style>
