import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'


import logout from '../components/web/session/logout.vue'
import tickets from '../router/tickets/index'
import adminSuper from '../router/adminSuper/index'
import admin from '../router/admin/index'
import products from '../router/shops/index'


Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/'  },
  { path: '/', name: 'Home', 
    //component: Home 
    component: () => import(/* webpackChunkName: "home" */ '../views/session/login.vue')
  },
  { path: '/error', name: 'Error403', 
    //component: Home 
    component: () => import(/* webpackChunkName: "home" */ '../views/errors/error.vue')
  },
  ...tickets,
  ...admin,
  ...adminSuper,
  ...products,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})


export default router
