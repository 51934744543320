// Importa la función
import { requiresSuperAdmin } from '../middleware/adminSuper';
import { requiresAdmin } from '../middleware/admin';

export default [
  //----- Super Admin -----
  { path: '/clients', name: 'Clients', 
    meta: { title: 'Dashboard555' }, 
    beforeEnter : requiresSuperAdmin ,
    component: () => import(/* webpackChunkName: "clients" */ '../../views/admin/clients/index.vue')
  },
  { path: '/roles', name: 'Roles', 
    meta: { title: 'Dashboard555' }, 
    beforeEnter : requiresSuperAdmin ,
    component: () => import(/* webpackChunkName: "roles" */ '../../views/admin/roles/index.vue')
  },
  { path: '/permissions', name: 'Permissions', 
    meta: { title: 'Dashboard555', requiresAuth: true },
    beforeEnter : requiresSuperAdmin,
    component: () => import(/* webpackChunkName: "permissions" */ '../../components/admin/roles/show.vue')
  },
  { path: '/user', name: 'user', 
    meta: { title: 'Dashboard555' },
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "admin" */ '../../views/admin/user/index.vue')
  },
];
