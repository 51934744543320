<template>
    <div>

        <div class="row">
            <div class="col">
                <!-- Image -->
                <div class="mb-3">
                    <div class="box-image">
                        <img class="style='max-width: 300px;'" :src="filteredImage.path" alt="">
                    </div>
                </div>
                <!-- Platform -->
                <div class="mb-3">
                    <label for="dataListPlatform" class="form-label">Platform</label>
                    <ClearableInput
                        inputId="dataListPlatform"
                        listId="datalistOptionsPlatformShop"
                        v-model="orderCreate.shop"
                        :items="shops"
                        placeholder="Type to search..."
                        name="platformShops"
                        @input="onPlatformChange"
                    />
                </div>
                <!-- Products -->
                <div class="mb-3">
                    <label for="datalistOptionsName" class="form-label">Product</label>
                    <InputValueKey
                        inputId="inputProduct"
                        listId="datalistOptionsName"
                        v-model="orderCreate.product"
                        placeholder="Enter your product"
                        name="product"
                        :items="filteredProducts"
                        @input="onProductChange"
                    />
                </div>
                <!-- Models/Colors -->
                <div class="mb-3">
                    <label for="exampleInputColor2" class="form-label">Models/Colors:</label>
                    <div class="form-check form-check-inline m-2" v-for="shop in filteredColors" :key="shop.id">
                        <input class="form-check-input" type="radio" name="model" :id="'checkboxColor-' + shop.id" :value="shop.name"
                            v-model="orderCreate.product.color">
                        <label class="form-check-label" :for="'checkboxColor-' + shop.id">{{ shop.name }}</label>
                    </div>
                </div>
                <!-- Colors -->
                <div class="mb-3" hidden>
                    <label for="exampleInputColor" class="form-label">Models/Colors</label>
                    <input type="text" class="form-control" id="exampleInputColor" name="colors" value="">
                </div>
                <!-- Prices -->
                <div class="mb-3">
                    <label for="exampleInputColor2" class="form-label">Prices:</label>
                    <div class="form-check form-check-inline m-2" v-for="data in filteredMoneys" :key="data.id">
                        <input class="form-check-input" type="radio" name="price" :id="'checkboxPrice-' + data.id" :value="data.price"
                            v-model="orderCreate.product.price">
                        <label class="form-check-label" :for="'checkboxPrice-' + data.id">{{ data.price }}</label>
                    </div>
                </div>
                <!-- Cantidad -->
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Cantidad</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        name="cantidad" v-model="orderCreate.product.cantidad" value="1">
                </div>
                <div class="col-4">
                    <!-- Cantidad -->
                    <div class="mb-3">
                    <label for="cantidad" class="form-label">Cantidad</label>
                    <div class="input-group">
                        <input type="text" class="form-control text-end" id="cantidad" name="cantidad" v-model="orderCreate.product.cantidad">
                        <button class="btn btn-outline-secondary" type="button" @click="decrement">-</button>
                        <button class="btn btn-outline-secondary" type="button" @click="increment">+</button>
                    </div>
                    </div>
                </div>
                <!-- Descuento -->
                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Descuento</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        name="descuento" v-model="orderCreate.product.descuento">
                </div>
                <!-- Adelanto -->
                <div class="mb-3" hidden>
                    <label for="exampleInputEmail1" class="form-label">Adelanto</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        name="adelanto" value="">
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <!-- Name -->
                        <div class="mb-3">
                            <label for="exampleInputName" class="form-label">Name</label>
                            <ClearableInputText
                                inputId="exampleInputName"
                                v-model="orderCreate.client.name"
                                placeholder="Enter your name"
                                name="name"
                            />
                        </div>
                    </div>
                    <div class="col">
                        <!-- Name Full-->
                        <div class="mb-3">
                            <label for="exampleInputNameFull" class="form-label">Full Name</label>
                            <ClearableInputText
                                inputId="exampleInputNameFull"
                                v-model="orderCreate.client.namefull"
                                placeholder="Enter your name full"
                                name="namefull"
                            />
                        </div>
                    </div>
                </div>
                <!-- Phone -->
                <div class="mb-3">
                    <label for="exampleInputPhone" class="form-label">Phone</label>
                    <ClearableInputText
                        inputId="exampleInputPhone"
                        v-model="orderCreate.client.phone"
                        placeholder="Enter your name full"
                        name="phone"
                    />
                </div>
                <!-- Document -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Document</label>
                    <ClearableInputText
                        inputId="exampleInputNameFull"
                        v-model="orderCreate.client.document"
                        placeholder="Enter your name document"
                        name="document"
                    />
                </div>
                <!-- Email -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Email</label>
                    <ClearableInputText
                        inputId="exampleInputNameFull"
                        v-model="orderCreate.client.email"
                        placeholder="Enter your name email"
                        name="email"
                    />
                </div>
                <hr>
                <!-- Departamentos -->
                <div class="mb-3">
                    <label for="dataListDepartaments" class="form-label">Departamentos</label>
                    <ClearableInput
                        inputId="dataListDepartaments"
                        listId="dataListDepartamentsNew"
                        v-model="orderCreate.destino.departamento"
                        :items="peru.departamentos"
                        placeholder="Type to search..."
                        @input="onDepartamentoChange"
                        name="departamentos"
                    />
                </div>
                <!-- Provincias -->
                <div class="mb-3">
                    <label for="dataListProvincias" class="form-label">Provincias</label>
                    <ClearableInput
                        inputId="dataListProvincias"
                        listId="datalistOptionsProvincia"
                        v-model="orderCreate.destino.provincia"
                        :items="filteredProvincias"
                        placeholder="Type to search..."
                        :disabled="!orderCreate.destino.departamento"
                        @input="onProvinciaChange"
                        name="provincias"
                    />
                </div>
                <!-- Distritos -->
                <div class="mb-3">
                    <label for="dataListDistritos" class="form-label">Distritos</label>
                    <ClearableInput
                        inputId="dataListDistritos"
                        listId="dataListOptionsDistritos"
                        v-model="orderCreate.destino.distrito"
                        :items="filteredDistritos"
                        placeholder="Type to search..."
                        :disabled="!orderCreate.destino.provincia"
                        name="distrito"
                    />
                </div>
                <!-- Dirección -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Dirección</label>
                    <ClearableInputText
                        inputId="exampleInputNameFull"
                        v-model="orderCreate.destino.direccion"
                        placeholder="Enter your name direccion"
                        name="direccion"
                    />
                </div>
                <!-- Referencia -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Referencia</label>
                    <ClearableInputText
                        inputId="exampleInputNameFull"
                        v-model="orderCreate.destino.referencia"
                        placeholder="Enter your name referencia"
                        name="referencia"
                    />
                </div>
                <hr>
                <!-- Agency -->
                <div class="mb-3">
                    <label for="dataListAgency" class="form-label">Agency</label>
                    <ClearableInput
                        inputId="dataListAgency"
                        listId="datalistOptionsAgency"
                        v-model="orderCreate.destino.agency"
                        :items="agencies"
                        placeholder="Type to search..."
                        name="agency"
                    />
                </div>
                <!-- Destination -->
                <div class="mb-3">
                    <label for="inputDestination" class="form-label">Destination</label>
                    <ClearableInputText
                        inputId="inputDestination"
                        v-model="orderCreate.destino.destination"
                        placeholder="Enter your name destination"
                        name="destination"
                    />
                </div>
                <!-- Observations -->
                <div class="mb-3">
                    <label for="inputNameObservations" class="form-label">Observations</label>
                    <ClearableInputText
                        inputId="inputNameObservations"
                        v-model="orderCreate.destino.observations"
                        placeholder="Enter your name observations"
                        name="observations"
                    />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import { mapState } from "vuex";

import PeruDepartamentos from '@/json/peru/departamentos.json';
import PeruProvincias from '@/json/peru/provinciasAll.json';
import PeruDistritos from '@/json/peru/distritosAll.json';

import ClearableInput from '@/components/input/ClearableInput.vue';
import ClearableInputText from '@/components/input/ClearableInputText.vue';
import InputValueKey from '@/components/input/InputValueKey.vue';

export default {
    name: 'CCreateOrder',
    components: {
        ClearableInput,
        ClearableInputText,
        InputValueKey,
    },
    data() {
        return {
            orderCreate: {
                products:[],
                shop: "TiendaPapaya",
                product: {
                    id:0,
                    color:'',
                    cantidad:1,
                    name:"",
                },
                client:{
                    name: "",
                    namefull: "",
                    phone: "",
                    document: "",
                    email: "",
                },
                destino:{
                    departamento: "",
                    provincia: "",
                    distrito: "",
                    agency: "",
                    direccion: "",
                    observations: "",
                    referencia: "",
                    destination: "",
                }
            },
            agencies: [
                { id: 1, name: "Shalom" },
                { id: 2, name: "Olva" },
            ],
            shops: [
                { id: 1, name: "TiendaPapaya", slug: "tiendapapaya.com" },
                { id: 3, name: "PequeMundos", slug: "pequemundos.com" },
                { id: 10, name: "PublicidadCapital", slug: "publicidadcapital.com" },
                { id: 4, name: "NONE", slug: "" },
            ],
            peru: {
                departamentos: PeruDepartamentos.departamentos,
                provincias: PeruProvincias.provinciasAll,
                distritos: PeruDistritos.distritosAll,
            },
            products: []
        }
    },
    created() {
        // this.getProducts();
    },
    methods: {
        getOrderData() {
            return this.orderCreate;
        },
        getProducts() {
            const url = this.appDomainApi + "shops/products/index?shop="+this.orderCreate.shop;
            axios.get(url,)
                .then(res => {
                    console.log(res)
                    this.products = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        onDepartamentoChange() {
            this.orderCreate.destino.provincia = '';
            this.orderCreate.destino.distrito = '';
        },
        onProvinciaChange() {
            this.orderCreate.destino.distrito = '';
        },
        onPlatformChange() {
            this.orderCreate.product = '';
        },
        onProductChange() {
            this.orderCreate.color = '';
            this.orderCreate.product.cantidad = 1;
        },
        increment() {
            this.orderCreate.product.cantidad++;
        },
        decrement() {
            if (this.orderCreate.product.cantidad > 1) {
                this.orderCreate.product.cantidad--;
            }
        },
    },
    // watch: {
        // status: {
        //     handler() {
        //         if (this.status) {
        //             console.log("status: "+this.status)
        //             this.ticket = [];
        //             this.ticket = this.itemTicket
        //         }
        //     },
        //     immediate: true, // Llama al handler inmediatamente cuando se monta el componente
        // },
    // },
    computed: {
        ...mapState(['shopsProducts']),
        filteredProvincias() {
            return this.peru.provincias[this.orderCreate.destino.departamento] || [];
        },
        filteredDistritos() {
            return this.peru.distritos[this.orderCreate.destino.provincia] || [];
        },
        filteredColors() {
            if (this.orderCreate.product.id) {
                console.log("filteredColors")
                var shopname = this.orderCreate.shop;
                console.log("shopname: "+shopname)
                // const item = this.products.find(item => item.id === this.orderCreate.product.id);
                if (this.shopsProducts && this.shopsProducts.products && this.shopsProducts.products[shopname]) {
                    const item = this.shopsProducts.products[shopname].find(item => item.id === this.orderCreate.product.id);
                    return item.colors;
                } else {
                    return []   
                }
            } else {
                return []
            }
        },
        filteredMoneys() {
            if (this.orderCreate.product.id) {
                console.log("filteredMoneys")
                // const item = this.products.find(item => item.id === this.orderCreate.product.id);
                var shopname = this.orderCreate.shop;
                console.log("shopname: "+shopname)
                if (this.shopsProducts && this.shopsProducts.products && this.shopsProducts.products[shopname]) {
                    const item = this.shopsProducts.products[shopname].find(item => item.id === this.orderCreate.product.id);
                    return item.moneys;
                } else {
                    return []
                }
            } else {
                return []
            }
        },
        filteredImage() {
            if (this.orderCreate.product.id) {
                console.log("filteredImage")
                // const item = this.products.find(item => item.id === this.orderCreate.product.id);
                var shopname = this.orderCreate.shop;
                console.log("shopname: "+shopname)
                if (this.shopsProducts && this.shopsProducts.products && this.shopsProducts.products[shopname]) {
                    const item = this.shopsProducts.products[shopname].find(item => item.id === this.orderCreate.product.id);
                    console.log(item.images)
                    return item.images;
                } else {
                    return []
                }
            } else {
                return []
            }
        },
        filteredProducts() {
            console.log("filteredProducts");
            if (this.shopsProducts) {
                // var shopname = this.orderCreate.shop.toLowerCase();
                var shopname = this.orderCreate.shop;
                const item = this.shopsProducts.products[shopname] || [];
                return item;
            } else {
                return []
            }
        },
    },
}
</script>

<style scoped>
.box-image{
    width: 300px; 
    height: 300px; 
    /* background: red; */
}
.box-image>img{
    max-width:300px
}
</style>