const actions = {
    copyToClipboard({ commit }, message) {
      console.log("copyToClipboard");
      console.log(message);
    
      var textarea = document.createElement("textarea");
      textarea.value = JSON.stringify(message);
      textarea.style.opacity = 0;
      document.body.appendChild(textarea);
    
      textarea.select();
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log(msg);
      } catch (err) {
        console.log(err);
      } finally {
        document.body.removeChild(textarea);
      }
    },
  
  };
  
  export default {
    namespaced: true,
    // state,
    // mutations,
    actions,
  };
      