<template>
    <div class="position-relative">
      <input
        :type="type"
        class="form-control"
        :id="inputId"
        v-model="inputValue"
        :placeholder="placeholder"
        :name="name"
        :disabled="disabled"
        ref="inputField"
        @input="updateValue"
        :list="listId"
      />
      <button v-if="inputValue && !disabled" @click="clearInput" class="btn-clear">
        &times;
      </button>
      <datalist :id="listId">
        <option v-for="item in items" :key="item.id" :value="item.title"></option>
      </datalist>
    </div>
  </template>
  
  <script>
  export default {
    name: "InputValueKey",
    props: {
      inputId: {
        type: String,
        required: true
      },
      listId: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: "text"
      },
      placeholder: {
        type: String,
        default: ""
      },
      value: {
        // type: Object,
        default: () => ({ id: '', title: '', title_small: '' })
      },
      items: {
        type: Array,
        required: true
      },
      name: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        inputValue: this.value.title
      };
    },
    watch: {
      value(val) {
        this.inputValue = val.title;
      }
    },
    methods: {
      updateValue(event) {
        const title = event.target.value;
        const item = this.items.find(item => item.title === title);
        const id = item ? item.id : '';
        const title_small = item ? item.title_small : '';
        this.$emit('input', { id, title, title_small });
      },
      clearInput() {
        this.$emit('input', { id: '', title: '', title_small: '' });
        this.$nextTick(() => {
          this.$refs.inputField.focus();
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .position-relative {
    position: relative;
  }
  .btn-clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: #000;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
  }
  .btn-clear:focus {
    box-shadow: none;
  }
  </style>
  