import store from "../../store";

export function requiresAdmin(to, from, next) {
  var isAuthenticated = false;
  const authroles = store.state.roles;
  if (authroles.includes("admin") || authroles.includes("super-admin"))
    isAuthenticated = true;
  else isAuthenticated = false;

  if (isAuthenticated) {
    next();
  } else {
    next({ name: "Error403" });
  }
}
