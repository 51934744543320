import axios from 'axios';

const state = {
  products: localStorage.getItem('products') || '',
};

const getters = {};

const actions = {

  async getProducts({ commit }) {
    console.log("shops//getProducts")
      
      const url = process.env.VUE_APP_DOMANAPI1 + 'shops/tickets/all-products'
    // const url = 'http://quiz-back-l7.test/api/v1/shop/tickets/all-products'

    try {
        var store = localStorage.getItem('products')
        let products = JSON.parse(store);

        if (products) {
            commit('getProducts', products);
        } else {
            const response = await axios.get(url);
            console.log(response)
            const products = JSON.stringify(response.data);
            commit('getProducts', products);
            localStorage.setItem('products', products);            
        }
      } catch (error) {
        console.error('getProducts error:', error);
        throw error;
      }
    
  },

};

const mutations = {
    getProducts(state, data) {
        state.products = data;
    },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
