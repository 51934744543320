import axios from "axios";

const state = {
  statuses: localStorage.getItem("statuses") || "",
};

const getters = {};

const actions = {
  async getStatuses({ commit }) {
    console.log("tickets/getStatuses");

    // const url = process.env.VUE_APP_DOMANAPI1 + "shops/tickets/all-products";
    // const url = 'http://quiz-back-l7.test/api/v1/shop/tickets/all-products'
    const url = process.env.VUE_APP_DOMANAPI1 + "shops/tickets/status";

    try {
      var store = localStorage.getItem("statuses");
      let dataStore = JSON.parse(store);

      if (dataStore) {
        commit("getStatuses", dataStore);
      } else {
        const response = await axios.get(url);
        console.log(response);
        const data = JSON.stringify(response.data);
        commit("getStatuses", data);
        localStorage.setItem("statuses", data);
      }
    } catch (error) {
      console.error("getStatuses error:", error);
      throw error;
    }
  },
};

const mutations = {
    getStatuses(state, data) {
    state.statuses = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
