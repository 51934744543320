import axios from 'axios';

const state = {
  adminData: {},
  token: localStorage.getItem('token') || '',
  user: null,
  authStatus: ''
};

const getters = {
  getAdminData: (state) => state.adminData,
  isAuthenticated: state => !!state.token,
  user: state => state.user,
  isLoading: state => state.isLoading,
  authError: state => state.authError
};

const actions = {

  async login({ commit }, user) {
    commit('AUTH_REQUEST');
    try {
      console.log("session/login")
    //   console.log(`${app.config.globalProperties.appDomain}`)
      const url = process.env.VUE_APP_DOMANAPI1 + 'admin/auth/login'

      const response = await axios.post(url, {
        email: user.email,
        password: user.password
      });
      const token = JSON.stringify(response.data.data);
      localStorage.setItem('access_token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      commit('AUTH_SUCCESS', token);
      //Traer datos despues del login
      // const userResponse = await axios.get('/api/auth/user');
      // commit('SET_USER', userResponse.data.user);
    //   await this.dispatch('session/fetchUser');
    } catch (error) {
      commit('AUTH_ERROR', error);
      localStorage.removeItem('access_token');
      throw error;
    }
  },
  async fetchUser({ commit }) {
    try {
      const response = await axios.get('YOUR_API_ENDPOINT/user', {
        headers: {
          'Authorization': `Bearer ${state.token}`
        }
      });
      commit('setUser', response.data);
    } catch (error) {
      console.error('Fetch user error:', error);
      throw error;
    }
  },
  async logout({ commit }) {
    commit('AUTH_LOGOUT');
    localStorage.removeItem('access_token');
    delete axios.defaults.headers.common['Authorization'];
  },
  // retrieveToken (context, credentials) {
  async retrieveToken ({ commit }) {
    console.log("retrieveToken")

    const token = localStorage.getItem('token');
    if (token) {
      commit('AUTH_SUCCESS', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // axios.get('/api/auth/user').then(response => {
      //   commit('SET_USER', response.data.user);
      // }).catch(() => {
      //   commit('AUTH_LOGOUT');
      //   localStorage.removeItem('token');
      //   delete axios.defaults.headers.common['Authorization'];
      // });
      //Traer datos despues del login
      // await this.dispatch('session/fetchUser');
    }

  },
  destroyToken (context) {
    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_DOMANAPI1 + 'admin/auth/logout', '', {
          headers: { Authorization: 'Bearer ' + context.state.token }
        })
          .then(response => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('student')
            localStorage.removeItem('navigation')
            localStorage.removeItem('navigationAdmin')
            context.commit('destroyToken')
            resolve(response)
            context.state.loggedIn = false
          })
          .catch(error => {
            localStorage.removeItem('access_token')
            context.commit('destroyToken')
            reject(error)
          })
      })
    }
  },
  async resetToken (context) {
    const access_token = `${window.localStorage.getItem('access_token')}`
    let store = JSON.parse(access_token);
    if(store){
      try {
        const url = "https://api.dabuho.com/api/v1/admin/auth/refreshtoken";
        axios
          .post(url, { 
              Accept: 'application/json',
              refreshtoken: `${store["refresh_token"]}` 
          })
          .then(function(response) {
              const token = response.data;
              localStorage.setItem('access_token', JSON.stringify(token));
              context.state.loggedIn = true;
              console.log("route go");
              this.router.go(0);
            });
      } catch (error) {
        console.error(error)
      }
    }
  },
};

const mutations = {
  setAdminData(state, data) {
    state.adminData = data;
  },
  retrieveToken (state, token) {
    state.token = token
  },
  destroyToken (state) {
    state.token = null
  },
  AUTH_REQUEST(state) {
    state.authStatus = 'loading';
  },
  AUTH_SUCCESS(state, token) {
    state.authStatus = 'success';
    state.token = token;
  },
  AUTH_ERROR(state) {
    state.authStatus = 'error';
  },
  AUTH_LOGOUT(state) {
    // state.token = '';
    // state.user = null;
    state.token = '';
    state.user = {};
    state.isLoading = false;
    state.authError = null;
    localStorage.removeItem('access_token');
  },
  SET_USER(state, user) {
    state.user = user;
  },
  resetToken(state) {
    state.token = '';
    state.user = {};
    state.isLoading = false;
    state.authError = null;
    localStorage.removeItem('access_token');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
