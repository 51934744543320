<template>
    <div>
        <div class="row">
            <!-- Lista de products -->
            <div class="col">
                <div>
                    <h2 class="text-center">Ticket</h2>
                    <div>
                        <p>
                            <b>id: </b> {{ ticket.id }}
                            <b>Code: </b> {{ ticket.code_order }}
                            <span v-if="ticket.status && ticket.status.name">
                                <b>Status: </b> {{ ticket.status.name }} <br>
                            </span>
                            <b>Created: </b> {{ ticket.created_at }} <br>
                            <b>Entrega: </b> {{ ticket.date_delivery_at }}
                        </p>
                    </div>
                    <!-- Status -->
                    <div class="mb-3">
                        <label for="exampleDataList" class="form-label">Status</label>
                        <ShopStoresInput inputId="exampleDataList" listId="datalistOptionsPlatform2"
                            :items="tickets.statuses" placeholder="Type to search..." name="status"
                            v-model="ticket.status" />
                    </div>
                    <!-- Platform -->
                    <div class="mb-3">
                        <label for="dataListPlatform" class="form-label">Platform</label>
                        <ShopStoresInput inputId="dataListPlatform" listId="datalistOptionsPlatform" :items="shops"
                            placeholder="Type to search..." name="platformShops" v-model="ticket.shop_store" />
                    </div>

                    <!-- Delivery Client -->
                    <div class="mb-3">
                        <label for="inputDelivery" class="form-label">Delivery</label>
                        <ClearableInputText inputId="inputDelivery" v-model="ticket.delivery_client"
                            placeholder="Enter your delivery" name="delivery" />
                    </div>
                    <!-- Delivery Included-->
                    <div class="mb-3">
                        <label for="inputDeliveryIncluded" class="form-label">Delivery Included</label>
                        <ClearableInputText inputId="inputDeliveryIncluded" v-model="ticket.delivery_included"
                            placeholder="Enter your delivery" name="delivery_included" />
                    </div>
                    <!-- Delivered-->
                    <div class="mb-3">
                        <label for="inputDeliveryIncluded" class="form-label">Deliver</label>
                        <date-picker v-model="ticket.date_delivery_at" format="YYYY-MM-DD" type="date"
                            placeholder="Select date"></date-picker>
                    </div>
                </div>
                <div>
                    <h2 class="text-center">Lista de products</h2>

                    <table class="table table-dark table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Status</th>
                                <th scope="col">Product</th>
                                <th scope="col">P. normal</th>
                                <th scope="col">Price</th>
                                <th scope="col">Dscto</th>
                                <th scope="col">Color</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in ticket.orders" :key="index" class="position-relative">
                                <th scope="row">{{ index + 1 }}</th>
                                <td v-if="order && order.product">
                                    <span v-if="order.status=='READY'" class="badge text-bg-primary">{{ order.status }}</span>
                                    <span v-else>{{ order.status }}</span>
                                </td>
                                <td v-else></td>
                                <td v-if="order && order.product">{{ order.product.title_small || order.product.title }}
                                </td>
                                <td v-else></td>
                                <td v-if="order && order.price_normal">{{ order.price_normal }}</td>
                                <td v-else></td>
                                <td v-if="order && order.price_discount">{{ order.price_discount }}</td>
                                <td v-else></td>
                                <td v-if="order && order.discount">{{ order.discount }}</td>
                                <td v-else></td>
                                <td v-if="order && order.colors">{{ order.colors }}</td>
                                <td v-else></td>
                                <td v-if="order && order.amount">{{ order.amount }}</td>
                                <td v-else></td>
                                <td>
                                    <button @click="productEdit(order.id)" class="">
                                        &#9998;
                                    </button>
                                    <button @click="clearProduct(order.id)" class="">
                                        &times;
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <b>Total Amount of Products:</b> {{ totalAmount }} <br>
                        <b>Total Discount:</b> {{ totalDiscount }} <br>
                        <b>Total Delivery:</b> {{ ticket.delivery_client }} <br>
                        <b>Total Price:</b> {{ totalPrice }}
                    </div>
                </div>
            </div>
            <!-- Payments -->
            <div class="col">
                <h2 class="text-center">Payments</h2>
                <!-- Method -->
                <div class="mb-3">
                    <label for="datalistOptionsMethod" class="form-label">Method</label>
                    <ShopStoresInput inputId="inputMethod" listId="datalistOptionsMethod"
                        :items="paymentMethods" placeholder="Type to search..." name="method"
                        v-model="newOrder.method" />
                </div>
                <!-- Payments -->
                <div class="mb-3">
                    <label for="inputPayment" class="form-label">Payment</label>
                    <ClearableInputText inputId="inputPayment" v-model="newOrder.addPayment"
                        placeholder="Enter your payment" name="payment" />
                </div>
                <div class="d-grid gap-2">
                    <button class="btn btn-primary" type="button" v-on:click="addPayment">Add</button>
                </div>

                <br>

                <table class="table table-dark table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Method</th>
                            <th scope="col">Payment</th>
                            <th scope="col">Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in ticket.order_payments" :key="index" class="position-relative">
                            <th scope="row">{{ index + 1 }}</th>
                            <td v-if="item && item.method">{{ item.method }}</td>
                            <td v-else></td>
                            <td v-if="item && item.payment" class="text_right">{{ item.payment }}</td>
                            <td v-else></td>
                            <td v-if="item && item.created_at">{{ item.created_at }}</td>
                            <td v-else></td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <b>Total Products:</b> {{ totalAmount }} <br>
                    <b>Total Price:</b> {{ totalPrice }} <br>
                    <b>Total Abono:</b> {{ totalSaldo }} <br>
                    <b>Resto:</b> {{ resto }} <br>
                </div>

                <div v-if="!editOrder.status">
                    <h2 class="text-center">Agregar product</h2>
                    <!-- Image -->
                    <div class="mb-3">
                        <div class="box-image">
                            <img class="style='max-width: 300px;'" :src="filteredImage.path" alt="">
                        </div>
                    </div>
                    <!-- Products -->
                    <div class="mb-3">
                        <label for="datalistOptionsName2" class="form-label">Product2</label>
                        <InputProduct inputId="inputProduct2" listId="datalistOptionsName2" v-model="newOrder.product"
                            placeholder="Enter your product" name="product" :items="filteredProducts" />
                    </div>
                    <!-- Status -->
                    <div class="row">
                        <div class="col-4">
                            <div class="mb-3">
                                <label for="inputStatus" class="form-label">Status</label>
                                <input type="text" class="form-control" id="inputStatus" aria-describedby="emailHelp"
                                    name="statusProduct" v-model="newOrder.status">
                            </div>
                        </div>
                    </div>
                    <!-- Models/Colors -->
                    <div class="mb-3" v-if="filteredColors && filteredColors.length > 0">
                        <label for="exampleInputColor2" class="form-label">Models/Colors:</label>
                        <div class="form-check form-check-inline m-2" v-for="shop in filteredColors" :key="shop.id">
                            <input class="form-check-input" type="radio" name="model" :id="'checkboxColor-' + shop.id"
                                :value="shop.name" v-model="newOrder.color">
                            <label class="form-check-label" :for="'checkboxColor-' + shop.id">{{ shop.name }}</label>
                        </div>
                    </div>
                    <!-- Prices -->
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label for="exampleInputColor2" class="form-label">Prices:</label>
                                <div class="form-check form-check-inline m-2" v-for="data in filteredMoneys" :key="data.id">
                                    <input class="form-check-input" type="radio" name="price"
                                        :id="'checkboxPrice-' + data.id" :value="data.price" v-model="newOrder.price">
                                    <label class="form-check-label" :for="'checkboxPrice-' + data.id">
                                        {{ data.price }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Cantidad | Descuento -->
                    <div class="row">
                        <div class="col-4">
                            <!-- Cantidad -->
                            <div class="mb-3">
                                <label for="cantidad" class="form-label">Cantidad</label>
                                <div class="input-group">
                                    <input type="text" class="form-control text-end" name="amount"
                                        v-model="newOrder.amount">
                                    <button class="btn btn-outline-secondary" type="button" @click="decrement">-</button>
                                    <button class="btn btn-outline-secondary" type="button" @click="increment">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <!-- Descuento -->
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Descuento</label>
                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                    name="discount" v-model="newOrder.discount">
                            </div>
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary" type="button" v-on:click="addProduct">Add</button>
                    </div>
                </div>
                <div v-else  @submit.prevent="formOrderUpdate('formOrderUpdate')" ref="formOrderUpdate">
                    <h2 class="text-center">Edit product</h2>
                    <!-- Image -->
                    <div class="mb-3">
                        <div class="box-image">
                            <img class="style='max-width: 300px;'" :src="filteredImage.path" alt="">
                        </div>
                    </div>
                    <!-- Products -->
                    <div class="mb-3">
                        <label for="datalistOptionsName2" class="form-label">Product1</label>
                        <InputProduct inputId="inputProduct2" listId="datalistOptionsName2" v-model="newOrder.product"
                            placeholder="Enter your product" name="product" :items="filteredProducts" />
                    </div>
                    <!-- Status -->
                    <div class="row">
                        <div class="col-4">
                            <div class="mb-3">
                                <label for="inputStatus" class="form-label">Status</label>
                                <input type="text" class="form-control" id="inputStatus" aria-describedby="emailHelp"
                                    name="statusProduct" v-model="newOrder.status">
                            </div>
                        </div>
                    </div>
                    <!-- Models/Colors -->
                    <div class="mb-3" v-if="filteredColors && filteredColors.length > 0">
                        <label for="exampleInputColor2" class="form-label">Models/Colors:</label>
                        <div class="form-check form-check-inline m-2" v-for="shop in filteredColors" :key="shop.id">
                            <input class="form-check-input" type="radio" name="model" :id="'checkboxColor-' + shop.id"
                                :value="shop.name" v-model="newOrder.color">
                            <label class="form-check-label" :for="'checkboxColor-' + shop.id">{{ shop.name }}</label>
                        </div>
                    </div>
                    <!-- Prices -->
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <label for="exampleInputColor2" class="form-label">Prices:</label>
                                <div class="form-check form-check-inline m-2" v-for="data in filteredMoneys" :key="data.id">
                                    <input class="form-check-input" type="radio" name="price"
                                        :id="'checkboxPrice-' + data.id" :value="data.price" v-model="newOrder.price">
                                    <label class="form-check-label" :for="'checkboxPrice-' + data.id">
                                        {{ data.price }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Cantidad | Descuento -->
                    <div class="row">
                        <div class="col-4">
                            <!-- Cantidad -->
                            <div class="mb-3">
                                <label for="cantidad" class="form-label">Cantidad</label>
                                <div class="input-group">
                                    <input type="text" class="form-control text-end" name="amount"
                                        v-model="newOrder.amount">
                                    <button class="btn btn-outline-secondary" type="button" @click="decrement">-</button>
                                    <button class="btn btn-outline-secondary" type="button" @click="increment">+</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <!-- Descuento -->
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Descuento</label>
                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                    name="discount" v-model="newOrder.discount">
                            </div>
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary" type="button" v-on:click="productUpdate">Update order</button>
                    </div>
                </div>
            </div>
        </div>
        <br><br>
        <div class="row">
            <!-- client -->
            <div class="col-6">
                <h2 class="text-center">Client</h2>
                <div class="row">
                    <div class="col">
                        <!-- Name -->
                        <div class="mb-3">
                            <label for="exampleInputName" class="form-label">Name</label>
                            <ClearableInputText inputId="exampleInputName" v-model="ticket.order_client.name"
                                placeholder="Enter your name" name="name" />
                        </div>
                    </div>
                    <div class="col">
                        <!-- Name Full-->
                        <div class="mb-3">
                            <label for="exampleInputNameFull" class="form-label">Full Name</label>
                            <ClearableInputText inputId="exampleInputNameFull" v-model="ticket.order_client.name_full"
                                placeholder="Enter your name full" name="namefull" />
                        </div>
                    </div>
                </div>
                <!-- Phone -->
                <div class="mb-3">
                    <label for="exampleInputPhone" class="form-label">Phone</label>
                    <ClearableInputText inputId="exampleInputPhone" v-model="ticket.order_client.phone"
                        placeholder="Enter your name full" name="phone" />
                </div>
                <!-- Document -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Document</label>
                    <ClearableInputText inputId="exampleInputNameFull" v-model="ticket.order_client.document"
                        placeholder="Enter your name document" name="document" />
                </div>
                <!-- Email -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Email</label>
                    <ClearableInputText inputId="exampleInputNameFull" v-model="ticket.order_client.email"
                        placeholder="Enter your name email" name="email" />
                </div>
                <hr>

            </div>
            <!-- Destino -->
            <div class="col-6">
                <h2 class="text-center">Destino</h2>
                <!-- Departamentos -->
                <!-- <div class="mb-3" hidden>
                    <label for="dataListDepartaments5" class="form-label">Departamentos NEW</label>
                    <InputKeyValue
                        inputId="dataListDepartaments5"
                        listId="dataListDepartamentsNew5"
                        v-model="ticket.order_destination.department"
                        :items="peru.departamentos"
                        placeholder="Type to search..."
                        @input="onDepartamentoChange"
                        name="departamentos"
                    />
                </div> -->
                <div class="mb-3">
                    <label for="dataListDepartaments" class="form-label">Departamentos</label>
                    <ClearableInput inputId="dataListDepartaments" listId="dataListDepartamentsNew"
                        v-model="ticket.order_destination.department" :items="peru.departamentos"
                        placeholder="Type to search..." @input="onDepartamentoChange" name="departamentos" />
                </div>
                <!-- Provincias -->
                <div class="mb-3">
                    <label for="dataListProvincias2" class="form-label">Provincias</label>
                    <ClearableInput inputId="dataListProvincias2" listId="datalistOptionsProvincia2"
                        v-model="ticket.order_destination.province" :items="filteredProvincias2"
                        placeholder="Type to search..." :disabled="!ticket.order_destination.department"
                        @input="onProvinciaChange()" name="provincias" />
                </div>
                <!-- Distritos -->
                <div class="mb-3">
                    <label for="dataListDistritos2" class="form-label">Distritos</label>
                    <ClearableInput inputId="dataListDistritos2" listId="dataListOptionsDistritos2"
                        v-model="ticket.order_destination.district" :items="filteredDistritos"
                        placeholder="Type to search..." :disabled="!ticket.order_destination.province"
                        name="distrito" />
                </div>
                <!-- Dirección -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Dirección</label>
                    <ClearableInputText inputId="exampleInputNameFull" v-model="ticket.order_destination.address"
                        placeholder="Enter your name direccion" name="direccion" />
                </div>
                <!-- Referencia -->
                <div class="mb-3">
                    <label for="exampleInputNameFull" class="form-label">Referencia</label>
                    <ClearableInputText inputId="exampleInputNameFull" v-model="ticket.order_destination.reference"
                        placeholder="Enter your name referencia" name="referencia" />
                </div>
                <hr>
                <!-- Agency -->
                <div class="mb-3">
                    <label for="dataListAgency" class="form-label">Agency</label>
                    <ClearableInput inputId="dataListAgency" listId="datalistOptionsAgency"
                        v-model="ticket.order_agency.agency" :items="agencies" placeholder="Type to search..."
                        name="agency" />
                </div>
                <!-- Destination -->
                <div class="mb-3">
                    <label for="inputDestination" class="form-label">Destination</label>
                    <ClearableInputText inputId="inputDestination" v-model="ticket.order_agency.destination"
                        placeholder="Enter your name destination" name="destination" />
                </div>
                <!-- Observations -->
                <div class="mb-3">
                    <label for="inputNameObservations" class="form-label">Observations</label>
                    <ClearableInputText inputId="inputNameObservations" v-model="ticket.order_agency.observations"
                        placeholder="Enter your name observations" name="observations" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import PeruDepartamentos from '@/json/peru/departamentos.json';
import PeruProvincias from '@/json/peru/provinciasAll.json';
import PeruDistritos from '@/json/peru/distritosAll.json';

import ClearableInput from '@/components/input/ClearableInput.vue';
import ClearableInputText from '@/components/input/ClearableInputText.vue';
// import InputValueKey from '@/components/input/InputValueKey.vue';
import InputProduct from '@/components/input/InputProduct.vue';
// import InputKeyValue from '@/components/input/InputKeyValue.vue';

import ShopStoresInput from '@/components/input/ShopStoresInput.vue';

export default {
    name: 'CUpdateOrder',
    components: {
        ClearableInput,
        ClearableInputText,
        // InputValueKey,
        InputProduct,
        // InputKeyValue,
        ShopStoresInput,
        DatePicker,
    },
    props: {
        itemTicket: {
            // type: Object||Array,
            required: true
        },
        // status:{
        //     required: true
        // }
    },
    data() {
        return {
            editOrder:{
                id:null,
                status:false,
                ticket_id:null,
                product_id:null,
                amount:null,
                colors:null,
                discount:null,
            },
            ticket: {
                orders: []
            },
            paymentMethods:[
                { id: 1, name: "Yape"},
                { id: 2, name: "Plin"},
                { id: 3, name: "BCP"},
                { id: 4, name: "Interbank"},
                { id: 5, name: "Scotiabank"},
                { id: 6, name: "Efectivo"},
            ],
            newOrder: {
                product: "",
                color: "",
                price: 0,
                amount: 1,
                discount: null,
                addPayment: null,
                status: null,
            },
            ticketDefault: {
                order_destination: {
                    destination: null,
                    department: null,
                    province: null,
                    district: null,
                    address: null,
                    observations: null,
                    reference: null,
                    ticket_id: null,
                },
                order_agency: {
                    ticket_id: null,
                    agency: null,
                    destination: null,
                },
                order_client: {
                    country_id: null,
                    document: null,
                    email: null,
                    name: null,
                    name_full: null,
                    phone: null,
                },
                orders: [
                    {
                        amount: 1,
                        colors: null,
                        discount: 0,
                        price_discount: 0,
                        price_normal: 0,
                        product: {
                            title_small: null,
                        },
                        product_id: null,
                        ticket_id: null,
                    }
                ]
            },
            // listProducts:[
            //     {
            //         ticket_id:0,
            //         product_id:0,
            //         amount:0,
            //         price_normal:0,
            //         price_discount:0,
            //         colors:null,
            //         discount:null,
            //     }
            // ],
            orderCreate: {
                products: [],
                shop: "",
                product: {
                    id: 0,
                    color: '',
                    amount: 1,
                    name: "",
                },
                client: {
                    name: "",
                    namefull: "",
                    phone: "",
                    document: "",
                    email: "",
                },
                destino: {
                    departamento: "",
                    provincia: "",
                    distrito: "",
                    agency: "Shalom",
                    direccion: "",
                    observations: "",
                    referencia: "",
                    destination: "",
                }
            },
            agencies: [
                { id: 1, name: "Shalom" },
                { id: 2, name: "Olva" },
            ],
            shops: [
                { id: 1, name: "TiendaPapaya", slug: "tiendapapaya.com" },
                { id: 3, name: "PequeMundos", slug: "pequemundos.com" },
                { id: 10, name: "PublicidadCapital", slug: "publicidadcapital.com" },
                { id: 4, name: "NONE", slug: "" },
            ],
            peru: {
                departamentos: PeruDepartamentos.departamentos,
                provincias: PeruProvincias.provinciasAll,
                distritos: PeruDistritos.distritosAll,
            },
            products: []
        }
    },
    created() {
        // this.getProducts();
        this.initializeTicketDefault();
    },
    mounted() {
        // this.listProducts = this.item.orders;
    },
    methods: {
        initializeTicketDefault(){
            if (!this.ticket.orders) {
                this.$set(this.ticket, 'orders', { ...this.ticketDefault.orders });
                // this.ticketDefault.orders[0] = this.ticket.id
                this.ticket.orders[0] = this.ticket.id
            }
            if (!this.ticket.order_destination) {
                this.ticketDefault.order_destination.ticket_id = this.ticket.id
                this.$set(this.ticket, 'order_destination', { ...this.ticketDefault.order_destination });
            }
            if (!this.ticket.order_client) {
                this.ticketDefault.order_client.ticket_id = this.ticket.id
                this.$set(this.ticket, 'order_client', { ...this.ticketDefault.order_client });
            }
            if (!this.ticket.order_agency) {
                this.ticketDefault.order_agency.ticket_id = this.ticket.id
                this.$set(this.ticket, 'order_agency', { ...this.ticketDefault.order_agency });
            }
        },
        addPayment(){
            console.log("addPayment")
            const url = this.appDomainApi + "shops/tickets/payment/store/" + this.ticket.id;

            const data = {
                "ticket_id":this.ticket.id,
                "method":this.newOrder.method.name,
                "payment":this.newOrder.addPayment,
            }

            axios.post(url, data)
                .then(res => {
                    console.log(res)
                    // this.ticket.order_payments = res.data;
                    this.$set(this.ticket, 'order_payments', res.data);
                })
                .catch(err => {
                    console.error(err);
                })

        },
        increment() {
            this.newOrder.amount++;
        },
        decrement() {
            if (this.newOrder.amount > 1) {
                this.newOrder.amount--;
            }
        },
        productUpdate() {
            console.log("addProduct")
            console.log(this.newOrder)

            const url = this.appDomainApi + "shops/tickets/order/update/" + this.ticket.id;
            const params = {
                ticket_id: this.ticket.id,
                order_id: this.newOrder.id,
                product_id: this.newOrder.product.id,
                amount: this.newOrder.amount,
                colors: this.newOrder.color,
                discount: Number(this.newOrder.discount),
                status: this.newOrder.status,
            };
            
            axios.post(url, params)
                .then(res => {
                    console.log(res)
                    // this.ticket.orders = res.data;
                    // this.$set(this.ticket, 'orders', res.data);
                    this.ticket = res.data;
                    // this.editOrder.status = !this.editOrder.status;
                    this.editOrder.status = !this.editOrder.status;
                    this.initializeTicketDefault();
                })
                .catch(err => {
                    console.error(err);
                })
        },
        addProduct() {
            console.log("addProduct")
            console.log(this.newOrder)
            // this.ticket.orders.push(this.newOrder)
            // this.ticket.orders.push(
            //     {
            //         amount: this.newOrder.cantidad,
            //         colors: this.newOrder.color,
            //         discount: this.newOrder.descuento,
            //         price_discount: this.newOrder.price,
            //         product: this.newOrder.product,
            //     }
            // )

            // this.newOrder.cantidad = 1;
            // this.newOrder.color = "";
            // this.newOrder.descuento = 0;
            // this.newOrder.price = 0;
            // this.newOrder.product = {};

            const url = this.appDomainApi + "shops/tickets/product/store/" + this.ticket.id;
            const params = {
                ticket_id: this.ticket.id,
                product_id: this.newOrder.product.id,
                amount: this.newOrder.amount,
                color: this.newOrder.color,
            };
            
            axios.post(url, params)
                .then(res => {
                    console.log(res)
                    // this.ticket.orders = res.data;
                    this.$set(this.ticket, 'orders', res.data);
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getOrderData() {
            var data = {
                ticket : this.ticket,
                totalDiscount : this.totalDiscount,
                totalPrice : this.totalPrice,
                totalSaldo : this.totalSaldo,
                resto : this.resto,
                
            }
            return data;
        },
        refreshTicket() {
            console.log("refreshTicket")
            const url = this.appDomainApi + "shops/tickets/refreshTicket/" + this.ticket.id;
            axios.get(url,)
                .then(res => {
                    console.log(res)
                    this.ticket = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getProducts() {
            // const url = this.appDomainApi + "shops/products/index?shop="+this.ticket.shop_store.name;
            const url = this.appDomainApi + "shops/products/index"+window.location.search;
            axios.get(url,)
                .then(res => {
                    console.log(res)
                    this.products = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        onDepartamentoChange() {
            console.log("onDepartamentoChange")
            // const key = event;
            // console.log(key.value)
            // console.log(key.name)
            // this.selectedDepartment = key;
            // this.ticket.order_destination.department = key.value;
            this.ticket.order_destination.province = '';
            this.ticket.order_destination.district = '';
        },
        onProvinciaChange() {
            console.log("onProvinciaChange")
            // const key = event;
            // console.log(key)
            // this.selectedProvince = key;
            this.ticket.order_destination.district = '';
        },
        onProductChange() {
            console.log("onProductChange")
            // const key = event;
            // console.log(key)
            this.orderCreate.color = '';
        },
        clearProduct(item_id) {
            console.log("clearProduct: " + item_id)
            // this.ticket.orders.splice(index, 1);
            const url = this.appDomainApi + "shops/tickets/product/related/" + this.ticket.id;

            const data = {
                order_id:item_id
            }
            axios.post(url,data)
                .then(res => {
                    console.log(res)
                    this.ticket.orders = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        productEdit(order_id) {
            console.log("productEdit: " + order_id)
            this.editOrder.status = !this.editOrder.status;
            console.log(this.editOrder.status)

            this.editOrder.id = order_id;

            this.orderFind(order_id);

            // this.ticket.orders.splice(index, 1);
        },
        orderFind(order_id){
            console.log("orderFind:"+order_id)
            const item = this.ticket.orders.find(item => item.id === order_id);
            console.log(item)
            this.newOrder = item;
        }
    },
    watch: {
        itemTicket: {
            handler() {
                // this.ticket = [];
                this.ticket = this.itemTicket
                // this.ticket.order_destination
                // this.ticket.orders
                // Verificar si order_detail está definido, si no, asignar la estructura predeterminada
                if (!this.ticket.orders) {
                    this.ticketDefault.orders[0] = this.ticket.id
                    this.$set(this.ticket, 'orders', { ...this.ticketDefault.orders });
                }
                if (!this.ticket.order_destination) {
                    this.ticketDefault.order_destination.ticket_id = this.ticket.id
                    this.$set(this.ticket, 'order_destination', { ...this.ticketDefault.order_destination });
                }
                if (!this.ticket.order_client) {
                    this.ticketDefault.order_client.ticket_id = this.ticket.id
                    this.$set(this.ticket, 'order_client', { ...this.ticketDefault.order_client });
                }
                if (!this.ticket.order_agency) {
                    this.ticketDefault.order_agency.ticket_id = this.ticket.id
                    this.$set(this.ticket, 'order_agency', { ...this.ticketDefault.order_agency });
                }
            },
            immediate: true, // Llama al handler inmediatamente cuando se monta el componente
        },
        // status: {
        //     handler() {
        //         if (this.status) {
        //             console.log("status: "+this.status)
        //             this.ticket = [];
        //             this.ticket = this.itemTicket
        //         }
        //     },
        //     immediate: true, // Llama al handler inmediatamente cuando se monta el componente
        // },
    },

    computed: {
        ...mapState(['shopsProducts', 'tickets']),
        filteredProvincias2() {
            if (this.ticket) {
                return this.peru.provincias[this.ticket.order_destination.department];
            } else {
                return []
            }
        },
        filteredDistritos() {
            if (this.ticket) {
                return this.peru.distritos[this.ticket.order_destination.province] || [];
            } else {
                return []
            }
        },
        filteredColors() {
            // if (this.newOrder.product.id) {
            //     const item = this.products.find(item => item.id === this.newOrder.product.id);
            //     return item.colors;
            // } else {
            //     return []
            // }
            if (this.orderCreate.product.id) {
                console.log("filteredColors")
                var shopname = this.orderCreate.shop;
                console.log("shopname: "+shopname)
                // const item = this.products.find(item => item.id === this.orderCreate.product.id);
                if (this.shopsProducts && this.shopsProducts.products && this.shopsProducts.products[shopname]) {
                    const item = this.shopsProducts.products[shopname].find(item => item.id === this.orderCreate.product.id);
                    return item.colors;
                } else {
                    return []   
                }
            } else {
                return []
            }
        },
        filteredMoneys() {
            // if (this.newOrder.product.id) {
            //     const item = this.products.find(item => item.id === this.newOrder.product.id);
            //     return item.moneys;
            // } else {
            //     return []
            // }
            if (this.newOrder && this.newOrder.product.id) {
                console.log("filteredMoneys")
                // const item = this.products.find(item => item.id === this.orderCreate.product.id);
                var shopname = this.newOrder.shop;
                console.log("shopname: "+shopname)
                if (this.shopsProducts && this.shopsProducts.products && this.shopsProducts.products[shopname]) {
                    const item = this.shopsProducts.products[shopname].find(item => item.id === this.newOrder.product.id);
                    return item.moneys;
                } else {
                    return []
                }
            } else {
                return []
            }
        },
        filteredImage() {
            // if (this.newOrder.product.id) {
            //     const item = this.products.find(item => item.id === this.newOrder.product.id);
            //     console.log("filteredImage")
            //     console.log(item)
            //     return item.images[0];
            // } else {
            //     return []
            // }

            if (this.newOrder.product.id) {
                console.log("filteredImage")
                // const item = this.products.find(item => item.id === this.orderCreate.product.id);
                var shopname = this.orderCreate.shop;
                console.log("shopname: "+shopname)
                if (this.shopsProducts && this.shopsProducts.products && this.shopsProducts.products[shopname]) {
                    const item = this.shopsProducts.products[shopname].find(item => item.id === this.newOrder.product.id);
                    console.log(item.images)
                    return item.images;
                } else {
                    return []
                }
            } else {
                return []
            }
        },
        filteredOrderEdit() {
            if (this.newOrder.product.id) {
                const item = this.products.find(item => item.id === this.newOrder.product.id);
                console.log("filteredImage")
                console.log(item)
                return item.images[0];
            } else {
                return []
            }
        },
        // filterListProducts(){
        //     const listProducts = this.item.orders;
        //     return listProducts;
        // }
        totalAmount() {
            if (this.ticket.orders && this.ticket.orders.length > 0) {
                return this.ticket.orders.reduce((sum, item) => {
                    if (item.status=='READY') {
                        return sum + Number(item.amount);
                    }
                    return sum;
                }, 0);
            } else {
                return 0;
            }
        },
        totalDiscountOld() {
            // return this.ticket.orders.reduce((sum, item) => sum + Number(item.price_normal), 0);
            if (this.ticket.orders && this.ticket.orders.length > 0) {
                return this.ticket.orders.reduce((sum, item) => {
                    return sum + (item.price_discount !== null ? Number(item.price_discount) : 0);
                }, 0);
            } else {
                return 0;
            }
        },
        totalDiscount() {
            // return this.ticket.orders.reduce((sum, item) => sum + Number(item.price_normal), 0);
            if (this.ticket.orders && this.ticket.orders.length > 0) {
                return this.ticket.orders.reduce((sum, item) => {
                    let price_discount = item.price_discount !== null ? Number(item.price_discount) : 0;
                    let discount = item.discount !== null ? Number(item.discount) : 0;
                    // return sum + price_discount - discount;
                    return sum + price_discount;
                }, 0);
            } else {
                return 0;
            }
        },
        totalDelivery() {
            if (this.ticket.orders && this.ticket.orders.length > 0) {
                return this.ticket.orders.reduce((sum, item) => {
                    return sum + (item.price_delivery !== null ? Number(item.price_delivery) : 0);
                }, 0);
            } else {
                return 0;
            }
        },
        totalPrice() {
            // return this.ticket.orders.reduce((sum, item) => sum + (item.price_discount * item.amount) + Number(item.delivery_client), 0);
            // return this.ticket.orders.reduce((sum, item) => {
            //     const discount = item.price_discount !== null ? Number(item.price_discount) : 0;
            //     const delivery = this.ticket.delivery_client !== null ? Number(this.ticket.delivery_client) : 0;
            //     console.log(discount)
            //     console.log(delivery)
            //     return sum + ((discount + delivery) * Number(item.amount));
            // }, 0);
            if (this.ticket.orders && this.ticket.orders.length > 0) {
                // Calcula el precio total incluyendo delivery una vez
                const total = this.ticket.orders.reduce((sum, item) => {
                    // const price_discount = item.price_discount !== null ? Number(item.price_discount) : 0;
                    var price_discount = 0;
                    if (item.status == 'READY') {
                        price_discount = item.price_discount !== null ? Number(item.price_discount) : 0;
                    } else {
                        price_discount = 0;                        
                    }
                    // const delivery = item.price_delivery !== null ? Number(item.price_delivery) : 0;
                    const discount = item.discount !== null ? Number(item.discount) : 0;

                    return sum + (price_discount * Number(item.amount)) - discount;
                }, 0);
                const delivery = this.ticket.delivery_client !== null ? Number(this.ticket.delivery_client) : 0;
                return total + delivery;
            } else {
                return 0;
            }
        },
        totalSaldo() {
            if (this.ticket.order_payments && this.ticket.order_payments.length > 0) {
                return this.ticket.order_payments.reduce((sum, item) => {
                    return sum + (item.payment !== null ? Number(item.payment) : 0);
                }, 0);
            } else {
                return 0;
            }
        },
        resto() {
            if (this.ticket.orders && this.ticket.orders.length > 0) {
                const totalPrice = this.totalPrice !== null ? Number(this.totalPrice) : 0;
                const totalSaldo = this.totalSaldo !== null ? Number(this.totalSaldo) : 0;
                return totalPrice - totalSaldo;
            } else {
                return 0;
            }
        },
        filteredProducts() {
            console.log("filteredProducts-----------");
            if (this.shopsProducts && this.ticket.shop_store) {
                // var shopname = this.orderCreate.shop.toLowerCase();
                var shopname = this.ticket.shop_store.name;
                const item = this.shopsProducts.products[shopname] || [];
                return item;
            } else {
                return []
            }
        },
    },
}
</script>

<style scoped>
.text_right{
    text-align: right;
}
.box-image {
    width: 300px;
    height: 300px;
    /* background: red; */
}

.box-image>img {
    max-width: 300px
}

.btn-clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    color: #000;
    font-size: 1.2rem;
    cursor: pointer;
    outline: none;
}

.btn-clear:focus {
    box-shadow: none;
}
</style>