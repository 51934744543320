import axios from "axios";

const state = {
  navigation:[],
  navigationAdmin:'',
  loadingNavigation: true,
  loadingNavigationAdmin: true,
};

const getters = {};

const actions = {
  async setNavigation({ commit }) {
    console.log("setNavigation--------------");
    this.state.loadingNavigation = true;
    const current = "navigation";
    const currentStorage = window.localStorage.getItem(current);
    if (currentStorage) {
      commit("setNavigation", JSON.parse(currentStorage));
      this.state.loadingNavigation = false;
    } else {
      try {
        const url = process.env.VUE_APP_DOMANAPI1 + "admin/profile/navigation";
        const token = `${window.localStorage.getItem("access_token")}`;
        let store = JSON.parse(token);
        console.log("store")
        console.log(store)
        await fetch(url, {
          headers: {
            Accept: "application/json",
            Authorization: `${store["token_type"]} ${store["access_token"]}`,
          },
        })
          .then((data) => data.json())
          .then((data) => {
            if (data.code == 401) {
              console.log("401");
              console.log("resetToken");
              this.dispatch("resetToken");
            } else {
              console.log("setNavigation")
              console.log(data)
              console.log()
              commit("setNavigation", data);
              window.localStorage.setItem(current, JSON.stringify(data));
              this.state.loadingNavigation = false;
            }
          });
      } catch (error) {
        console.error(error);
      }
    }
  },
  async setNavigationAdmin({ commit }) {
    console.log("setNavigationAdmin");
    this.state.loadingNavigationAdmin = true;
    const current = "navigationAdmin";
    const currentStorage = window.localStorage.getItem(current);
    if (currentStorage) {
      commit("setNavigationAdmin", JSON.parse(currentStorage));
      this.state.loadingNavigationAdmin = false;
    } else {
      try {
        const url =
          process.env.VUE_APP_DOMANAPI1 + "admin/profile/navigation-admin";
        const token = `${window.localStorage.getItem("access_token")}`;
        let store = JSON.parse(token);
        await fetch(url, {
          headers: {
            Accept: "application/json",
            Authorization: `${store["token_type"]} ${store["access_token"]}`,
          },
        })
          .then((data) => data.json())
          .then((data) => {
            console.log(data);
            if (data.code == 401) {
              console.log("401");
              console.log("resetToken");
              this.dispatch("resetToken");
            } else {
              commit("setNavigationAdmin", data);
              window.localStorage.setItem(current, JSON.stringify(data));
              this.state.loadingNavigationAdmin = false;
            }
          });
      } catch (error) {
        console.error(error);
      }
    }
  },
};

const mutations = {
  setNavigation(state, navigation) {
    state.navigation = navigation;
  },
  setNavigationAdmin(state, navigationAdmin) {
    state.navigationAdmin = navigationAdmin;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
