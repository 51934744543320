<template>
    <div>
        Tickets
        <cTickets />

    </div>
</template>

<script>
import cTickets from '@/components/tickets/index.vue'

export default {
  name: 'vTickets',
  components: {
    cTickets
  },
}
</script>

<style scope></style>


