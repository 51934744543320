<template>
  <header class="header" :class="{scrolled}">
    <div class="header_overlay" v-on:click="close"></div>
    <div class="header_content d-flex flex-row align-items-center justify-content-start">
      <router-link :to="{ name: 'Home' }">
        <div id="logo" class="imglogo" style="margin:15px; width:30px;">
          <img :src="'/svg/icon/logo-blanco-30.png'" alt="logo daBuho" title="daBuho">
        </div>
      </router-link>
      <div class="logo">
        <router-link :to="{ name: 'Home' }">daBuho</router-link>
      </div>

      <!-- Header Nav -->
      <div
        class="header_right d-flex flex-row align-items-center justify-content-end"
      >
        <nav class="main_nav">
          <ul class="d-flex flex-row align-items-center justify-content-start">
            <li class="active">
              <a href="#" hidden>Home</a>
            </li>
          </ul>
        </nav>
        <div class="log_reg">
            <!-- Authentication Links -->
            <ul class="d-flex flex-row align-items-center justify-content-start" v-if="!loggedIn">
              <li>
                <router-link :to="{ name: 'login' }" class="btn btn-outline-primary btn-lg mr-4">Iniciar sesión</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Register' }" class="btn btn-primary btn-lg">Únete ahora</router-link>
              </li>
            </ul>
            <ul v-else>
              <li class="btn_course">
                <router-link :to="{ name: 'Home' }">Cursos</router-link>
              </li>
              <li class="btn_course">
                <Hamburger />
              </li>
            </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Hamburger from "@/components/layouts/sidebar/score/scoreHamburger.vue";

export default {
  name: "HeaderIndex",
  components: {
    Hamburger,
  },
  data() {
    return {
      scrolled: true, 
      lastScrollPosition: 0 
    };
  },
  created() {
  },
  mounted() {
    window.addEventListener('scroll', this.setHeader);
  },
  beforeDestroy() {
    window.removeEventListener ('scroll', this.setHeader) 
  },
  methods: {
    close() {
      console.log("button close11");
      var menu = document.querySelector(".menu");
      menu.classList.remove("active");
    },
    setHeader(){
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop 
      if (currentScrollPosition <0) { 
        return
      }
      if (Math.abs (currentScrollPosition - this.lastScrollPosition) <60) { 
        return
      } 
      this.scrolled = currentScrollPosition < this .lastScrollPosition 
      this.lastScrollPosition = currentScrollPosition 
    }
  },
  computed: {
    ...mapGetters(['loggedIn'])
  }
};
</script>

<style scope>
/*********************************
3. Header
*********************************/
/*li {
  float: left;
}*/
.header_right {
  width: 100%;
}
.btn_course {
  float: left;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #ffffff;
}
.header{
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms cubic-bezier(0.4, 0, 1, 1);
}
.header.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms cubic-bezier(0.4, 0, 1, 1);
  background: #0c2790;
  /*background: #F5F9FA;*/
  /*visibility: visible;*/
}
.header.active {
  -webkit-transform: translateX(-350px);
  -moz-transform: translateX(-350px);
  -ms-transform: translateX(-350px);
  -o-transform: translateX(-350px);
  transform: translateX(-350px);
  transition: all 600ms cubic-bezier(0.4, 0, 1, 1);
}
.header_overlay {
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 800ms ease;
  -moz-transition: all 800ms ease;
  -ms-transition: all 800ms ease;
  -o-transition: all 800ms ease;
  /*transition: all 800ms ease;*/
  transition: all 600ms cubic-bezier(0.4, 0, 1, 1);
}
.header.active .header_overlay {
  visibility: visible;
  opacity: 1;
}
.header_content {
  width: 100%;
  height: 128px;
  padding-left: 50px;
  padding-right: 50px;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
}
.header_content {
  height: 90px;
}
.logo a {
  font-size: 24px;
  color: #ffffff;
}
.logo a span {
  color: #ffb400;
}
.logo a span:last-child {
  font-weight: 700;
}
.main_nav ul li:not(:last-of-type) {
  margin-right: 59px;
}
.main_nav ul li a {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}
.main_nav ul li.active a {
  color: #ffb400;
}
.log_reg {
  margin-left: 28px;
}

.log_reg ul li a {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}
.log_reg ul li:first-child a {
  color: #ffffff;
}
</style>
