import store from '../../store'

export function requiresSuperAdmin(to, from, next)
{
  var isAuthenticated= false;
  const authroles = store.state.roles;
  if(authroles.includes('super-admin'))
    isAuthenticated = true;
  else
    isAuthenticated= false;

  if(isAuthenticated) 
  {
    next();
  } 
  else
  {
    next({ name: 'Error403' });
  }
}