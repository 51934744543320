<template>
  <div>
    <div v-if="navigation.loadingNavigation.length < 1">
      <loadingNav />
    </div>

    <div class="navbar bg-body-tertiary" id="navbarScroll">
      <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
        <li class="nav-item dropdown" v-for="(navigation, index) in navigation.navigationAdmin" :key="index">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{
            navigation.name }}</a>
          <ul class="dropdown-menu">
            <li v-for="producto in navigation.productos" :key="producto.route">
              <a class="dropdown-item" :href="producto.route">{{ producto.name }}</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Link
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" aria-disabled="true">Link</a>
        </li>
      </ul>
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import loadingNav from "@/views/constants/loading/navigation.vue";

export default {
  name: "navigation",
  components: {
    loadingNav,
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("setStudent");
    this.$store.dispatch("navigation/setNavigationAdmin");
  },
  mounted() {
    console.log("componet navigation")
    console.log(this.navigation.navigation)
  },
  methods: {
    closeMenu() {
      console.log("remove class");
      var header = document.querySelector(".header");
      var menu = document.querySelector(".menu");
      var sup = document.querySelector('.super_container_inner');
      header.classList.remove("active");
      menu.classList.remove("active");
      sup.classList.remove("active");
    },
  },
  computed: {
    ...mapState(["session", "student", "navigation"]),
  },
};
</script>

<style scoped>
a,
a:hover,
a:visited,
a:active,
a:link {
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}
</style>
