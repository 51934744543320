// Importa la función
import { requiresSuperAdmin } from '../middleware/adminSuper';
import { requiresAdmin } from '../middleware/admin';

export default [
  //----- Admin -----
  { path: '/admin', name: 'Admin', 
    meta: { title: 'Dashboard555', requiresAuth:true },
    component: () => import(/* webpackChunkName: "admin" */ '../../views/admin/index.vue')
  },
  { path: '/import-question', name: 'importQuestion', 
    meta: { title: 'Dashboard555'},
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "importquestion" */ '../../views/admin/import/question/index.vue')
  },
  { path: '/question-show/:url', name: 'importQuestionShow', 
    meta: { title: 'Dashboard555' },
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "importquestionshow" */ '../../components/admin/import/question/show.vue')
  },
  { path: '/contacts', name: 'Contacts', 
    meta: { requiresAuth: true },
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "contacts" */ '../../views/admin/contact/index.vue')
  },
  { path: '/jobs', name: 'Jobs', 
    meta: { requiresAuth: true },
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "jobs" */ '../../components/admin/jobs/index.vue')
  },
  { path: '/view-round', name: 'viewRounds', 
    meta: { requiresAuth: true }, 
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "viewrounds" */ '../../views/admin/rounds/index.vue')
  },
  { path: '/view-versus', name: 'viewVersus', 
    meta: { requiresAuth: true }, 
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "viewversus" */ '../../views/admin/versus/index.vue')
  },
  { path: '/view-generate-favorite', name: 'viewGenerateFavorite', 
    meta: { requiresAuth: true },
    beforeEnter : requiresAdmin,
    component: () => import(/* webpackChunkName: "viewgeneratefavorite" */ '../../views/admin/generate/index.vue')
  },
];
